export const SendlIcon = ({ className = '', color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
    >
      <g clipPath="url(#clip0_5245_4710)">
        <path
          d="M15.2065 0.03119L0.443907 4.92342C-0.0773966 5.09814 -0.160461 5.7999 0.309284 6.08919L4.87498 8.63842C5.23589 8.86184 5.6999 8.83319 6.03216 8.56681L10.5033 5.33588C10.6122 5.24709 10.7554 5.3903 10.6666 5.49915L7.43567 9.97032C7.16929 10.3026 7.14065 10.7666 7.36406 11.1275L9.91329 15.6932C10.2026 16.1601 10.9043 16.0799 11.0791 15.5586L15.9684 0.793095C16.126 0.323349 15.6763 -0.123482 15.2065 0.03119Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5245_4710">
          <rect width="16" height="16"/>
        </clipPath>
      </defs>
    </svg>
  );
};
