import React, { memo, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './up-down-transition-text.module.scss';

interface IUpDownTransitionText {
  text: string | null;
}

export const UpDownTransitionText: React.FC<IUpDownTransitionText> = memo(
  function UpDownTransitionText({ text = null }) {
    const textArray = (text || '').split(' ');

    const container = {
      hidden: { opacity: 0 },
      visible: (i = 1) => ({
        opacity: 1,
        transition: { staggerChildren: 0.12, delayChildren: 0.04 * i },
      }),
    };

    const child = {
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          damping: 12,
          stiffness: 100,
        },
      },
      hidden: {
        opacity: 0,
        y: 10,
        transition: {
          damping: 12,
          stiffness: 100,
        },
      },
    };

    return (
      <motion.span
        key={text}
        className={styles.chatStateContainer}
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {textArray.map((word, index) => (
          <motion.span
            className="chatStateText"
            variants={child}
            style={{ marginRight: '5px' }}
            key={index}
          >
            {word}
          </motion.span>
        ))}
      </motion.span>
    );
  },
);
