import { useEffect, useRef, useState } from 'react';
import type { LottiePlayer as LottiePlayerType } from 'lottie-web';
import styles from './lottie-player.module.scss';

export interface ILottiePlayerProps {
  className?: string;
  autoplay?: boolean;
  loop?: boolean;
  src: string;
}

export const LottiePlayer: React.FC<ILottiePlayerProps> = ({
  src,
  autoplay = true,
  className,
  loop = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayerType | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: autoplay,
        // path to your animation file, place it inside public folder
        path: `/animations/${src}`,
      });

      return () => animation.destroy();
    }
  }, [lottie, src, autoplay, loop]);

  return (
    <div ref={ref} className={`${styles.playerContainer} ${className || ''}`} />
  );
};
