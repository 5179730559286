import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styles from './progress-bar.module.scss';

export interface IProgressBarRef {
  next: () => void;
}

export interface IProgressBarProps {
  loading: boolean;
  onAnimatedToTheEnd?: () => void;
}

export const ProgressBar = forwardRef<IProgressBarRef, IProgressBarProps>(
  function ProgressBar({ loading, onAnimatedToTheEnd }, ref) {
    const [progress, setProgress] = useState(0);
    const speedRef = useRef<number>(30);

    useImperativeHandle(ref, () => {
      return {
        next: () => {
          let newProgress = progress;

          if (progress < 70) {
            newProgress += speedRef.current;
            // Reduce speed
            speedRef.current /= 2;
            if (speedRef.current < 1) speedRef.current = 1;
          } else {
            newProgress++;
          }

          setProgress(Math.min(newProgress, 100));
        },
      };
    });

    useEffect(() => {
      let timeout: NodeJS.Timeout;

      if (!loading) {
        setProgress(100);

        timeout = setTimeout(() => {
          onAnimatedToTheEnd?.();
          speedRef.current = 30;
        }, 200);
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [loading, onAnimatedToTheEnd]);

    return (
      <div
        className={styles.container}
        style={{
          width: `${progress}%`,
        }}
      ></div>
    );
  },
);
