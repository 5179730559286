export enum SupportedFlags {
	ImageUpload = 'image-upload',
	SavedRecipients = 'saved-recipients',
	SignUpCtaExperience = 'sign-up-cta-experience'
}

export const useFeatureFlags = () => {
	function getFlagValueForFeature(feature: SupportedFlags | string) {
		if (feature === SupportedFlags.ImageUpload) {
			return process.env.NEXT_PUBLIC_IMAGE_UPLOAD_FEATURE_FLAG === 'enabled';
		}

		if (feature === SupportedFlags.SavedRecipients) {
			// Uncomment below line if you want to control this flag from env variables
			// setFlagValue(process.env.NEXT_PUBLIC_SAVED_RECIPIENTS_FEATURE_FLAG === 'enabled');
			return true;
		}

		return false;
	}

	return {
		getFlagValueForFeature
	};
};
