import { useEffect, useState } from 'react';
import { UpDownTransitionText } from '../up-down-transition-text';

import styles from './placeholder.module.scss';

export const Placeholder: React.FC<{
  placeholders?: string[];
  delay?: number;
  onClick?: () => void;
}> = ({ placeholders, delay = 5000, onClick }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    let interval: number | null = null;

    interval = window.setInterval(() => {
      setCurrentIndex(
        placeholders && currentIndex === placeholders.length - 1
          ? 0
          : currentIndex + 1,
      );
    }, delay);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentIndex, setCurrentIndex, delay, placeholders]);

  return (
    <div className={styles.placeholder} onClick={onClick}>
      <UpDownTransitionText text={placeholders?.[currentIndex] || ''} />
    </div>
  );
};
