import styles from './message-capsules.module.scss';

export interface IMessageCapsulesProps {
  className?: string;
  selection?: string[];
  active?: boolean;
  marked?: string | string[];
  skeleton?: number;
  onSuggest?: (text: string) => void;
}

export const MessageCapsules: React.FC<IMessageCapsulesProps> = ({
  className,
  selection,
  active,
  marked,
  skeleton,
  onSuggest,
}) => {
  const markedElements = Array.isArray(marked) ? marked : [marked];
  const suggestClick = (item: string) => {
    if (active) {
      onSuggest?.(item);
    }
  };

  if (skeleton) {
    return (
      <div className={`${className || ''} ${styles.container}`}>
        <div className="skeletonContainer">
          <div className="skeletonOverlay"></div>
          {Array(skeleton)
            .fill(null)
            .map((_, idx) => (
              <div className={`capsule skeletonCapsule`} key={idx}>
                &nbsp;
              </div>
            ))}
        </div>
      </div>
    );
  }

  return selection?.length ? (
    <div className={`${className || ''} ${styles.container}`}>
      {selection.map((item) => (
        <div
          className={`${
            markedElements.some(
              (marked) => {
                return marked?.toLocaleLowerCase() === item.toLocaleLowerCase();
              }
            )
              ? 'marked'
              : ''
          } ${'capsule'} ${!active ? 'capsuleDisabled' : ''}`}
          key={item}
          onClick={() => suggestClick(item)}
        >
          {item}
        </div>
      ))}
    </div>
  ) : null;
};
